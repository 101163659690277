import { SELECTED_PROGRAM } from '../constants';

export default function runtime(state = {}, action) {
  switch (action.type) {
    case SELECTED_PROGRAM:
      return {
        ...state,
        selectedProgram: action.selectedProgram,
      };
    default:
      return state;
  }
}
