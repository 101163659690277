exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3dP95{background:#333;color:#fff;width:100%;position:absolute;bottom:0;min-height:130px}._3dP95,._26pKB{text-align:center}._26pKB{margin:0 auto;padding:20px 15px;max-width:1000px}.tTplm{color:hsla(0,0%,100%,.5)}._3n7Lv{color:hsla(0,0%,100%,.3)}.NoJN3,.tTplm{padding:2px 5px;font-size:1em}.NoJN3,.NoJN3:active,.NoJN3:visited{color:#fff;text-decoration:none}.NoJN3:hover{color:hsla(0,0%,100%,.6)}", ""]);

// exports
exports.locals = {
	"root": "_3dP95",
	"container": "_26pKB",
	"text": "tTplm",
	"spacer": "_3n7Lv",
	"link": "NoJN3"
};