import { SELECTED_CASE, CASE_LIST } from '../constants';

export default function cases(state = {}, action) {
  switch (action.type) {
    case SELECTED_CASE:
      return {
        ...state,
        selectedCase: action.selectedCase,
      };
    case CASE_LIST:
      return {
        ...state,
        caseList: action.caseList,
      };
    default:
      return state;
  }
}
