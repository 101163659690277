exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._13MfM{padding-top:15px;text-align:center}._3kj_G,._3kj_G:active,._3kj_G:hover,._3kj_G:visited{text-decoration:none}._3kj_G:hover{text-decoration:underline}", ""]);

// exports
exports.locals = {
	"container": "_13MfM",
	"link": "_3kj_G"
};