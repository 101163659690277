import { USER, ALLOWED_USER, USER_PROGRAM, USER_SPECIALTY } from '../constants';

const initialState = {
  user: {},
  allowedUser: {},
  program: {},
  specialty: {},
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case USER:
      return {
        ...state,
        user: action.user,
      };
    case ALLOWED_USER:
      return {
        ...state,
        allowedUser: action.allowedUser,
      };
    case USER_PROGRAM:
      return {
        ...state,
        program: action.program,
      };
    case USER_SPECIALTY:
      return {
        ...state,
        specialty: action.specialty,
      };
    default:
      return state;
  }
}
