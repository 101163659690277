/* eslint-disable import/prefer-default-export */
import localForage from 'localforage';
import {
  USER, ALLOWED_USER, USER_PROGRAM, USER_SPECIALTY, REPORT_FETCHED_DATE, REPORT_CASES,
} from '../constants';

export function loginUser(user) {
  return {
    type: USER,
    user,
  };
}

export function logoutUser() {
  localForage.removeItem('persist:reports');
  return (dispatch) => {
    dispatch({
      type: USER,
      user: {},
    });
    dispatch({
      type: ALLOWED_USER,
      user: {},
    });
    dispatch({
      type: USER_PROGRAM,
      user: {},
    });
    dispatch({
      type: USER_SPECIALTY,
      user: {},
    });
    dispatch({
      type: REPORT_FETCHED_DATE,
      lastFetchedDate: null,
    });
    dispatch({
      type: REPORT_CASES,
      cases: null,
    });
  };
}

export function setAllowedUser(allowedUser) {
  return {
    type: ALLOWED_USER,
    allowedUser,
  };
}

export function setUserAndAllowedUser({ user, allowedUser }) {
  return (dispatch) => {
    dispatch(loginUser(user));
    dispatch(setAllowedUser(allowedUser));
  };
}

export function setUserProgram(program) {
  return {
    type: USER_PROGRAM,
    program,
  };
}

export function setUserSpecialty(specialty) {
  return {
    type: USER_SPECIALTY,
    specialty,
  };
}
