import { SPECIALTIES, SELECTED_SPECIALTY } from '../constants';

export default function specialty(state = {}, action) {
  switch (action.type) {
    case SPECIALTIES:
      return {
        ...state,
        specialties: action.specialties,
      };
    case SELECTED_SPECIALTY:
      return {
        ...state,
        selectedSpecialty: action.selectedSpecialty,
      };
    default:
      return state;
  }
}
