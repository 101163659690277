/* eslint-disable import/prefer-default-export */

import { IS_AUTH, IS_ADMIN, IS_GLOBAL_ADMIN } from '../constants';

export function setIsAuth(isAuth) {
  return {
    type: IS_AUTH,
    isAuth,
  };
}

export function setIsAdmin(isAdmin) {
  return {
    type: IS_ADMIN,
    isAdmin,
  };
}

export function setIsGlobalAdmin(isGlobalAdmin) {
  return {
    type: IS_GLOBAL_ADMIN,
    isGlobalAdmin,
  };
}

export function setAuthProps({ isAuth, isAdmin, isGlobalAdmin }) {
  return (dispatch) => {
    dispatch(setIsAuth(isAuth));
    dispatch(setIsAdmin(isAdmin));
    dispatch(setIsGlobalAdmin(isGlobalAdmin));
  };
}
