import { IS_AUTH, IS_ADMIN, IS_GLOBAL_ADMIN } from '../constants';

export default function user(state = {}, action) {
  switch (action.type) {
    case IS_AUTH:
      return {
        ...state,
        isAuth: action.isAuth,
      };
    case IS_ADMIN:
      return {
        ...state,
        isAdmin: action.isAdmin,
      };
    case IS_GLOBAL_ADMIN:
      return {
        ...state,
        isGlobalAdmin: action.isGlobalAdmin,
      };
    default:
      return state;
  }
}
