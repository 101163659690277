exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3bMN6{background:#4b82d0;background:-webkit-linear-gradient(top left,#4b82d0,#335482);background:-webkit-gradient(linear,left top,right bottom,from(#4b82d0),to(#335482));background:-o-linear-gradient(top left,#4b82d0,#335482);background:linear-gradient(to bottom right,#4b82d0,#335482);height:100px;position:fixed;width:100%}._2n5KC{max-width:57px}._3Vl-0{margin-right:100px}", ""]);

// exports
exports.locals = {
	"header": "_3bMN6",
	"logoImage": "_2n5KC",
	"navItems": "_3Vl-0"
};